import request from '@/utils/request';

export default function listDictByType(type) {
  return request({
    url: '/common/api/dict/listByType',
    method: 'get',
    params: {
      type
    }
  });
}

// 口岸
export function listCustoms() {
  return listDictByType('order_global_customs');
}

export function longToShort(param) {
  return request({
    url: `/notify-service/api/qrcode/longToShort?param=${param}`,
    method: 'get'
  });
}

export function shortToLong(param) {
  return request({
    url: `/notify-service/api/qrcode/shortToLong?param=${param}`,
    method: 'get'
  });
}
