<template>
  <span>{{ filterOffsetTime(offsetTime) }}</span>
</template>
<script>
// 倒计时
import floor from 'lodash/floor';

export default {
  name: 'TimeClock',
  props: {
    date: {
      type: Number,
      required: true
    },
    interval: {
      type: Number,
      default: 100
    },
    // 显示毫秒
    showMillisecond: {
      type: Boolean,
      default: true
    },
    // 显示天
    showDay: {
      type: Boolean,
      default: false
    },
    // 超过24小时显示天 否则显示时分秒
    isShort: Boolean
  },
  mounted() {
    this.stop = this.start();
  },
  destroyed() {
    this.stop();
  },
  computed: {
    offsetTime() {
      return this.date > this.now ? this.date - this.now : 0;
    }
  },
  methods: {
    start() {
      const pid = setInterval(() => {
        this.now = Date.now();
      }, this.interval);
      return () => clearInterval(pid);
    },
    filterOffsetTime(timestamp) {
      const getNum = num => {
        return num >= 10 ? num : `0${num}`;
      };
      // 递增换算序列
      const timeList = [];
      let time = floor(timestamp / 1000);

      [60, 60].forEach(num => {
        timeList.unshift(time % num);
        time = floor(time / num);
      });
      // 是否显示天
      timeList.unshift(this.showDay ? time % 24 : time);

      let str = timeList.map(getNum).join(':');

      if (this.showMillisecond) {
        const milliseconds = floor((timestamp % 1000) / 100);
        str = `${str}.${milliseconds}`;
      }
      const day = floor(time / 24);
      if (this.showDay) {
        str = `${day}天${str}`;
      }
      return this.isShort && day > 0 ? `${day}天` : str;
    }
  },
  watch: {
    now(val) {
      if (val > this.date) {
        this.stop();
        this.$emit('stop');
        this.isStop = true;
      }
    }
  },
  data() {
    return {
      now: Date.now(),
      isStop: false
    };
  }
};
</script>
