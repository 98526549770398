import { withExtTenantIdRequest } from '@/utils/request';
// 我的小样记录分页列表
export function sampleApplyRecordMyList(data) {
  return withExtTenantIdRequest({
    url: `/soyoungzg/api/sampleApplyRecord/myList`,
    method: 'post',
    data
  });
}

// 删除记录
export function handleDeleteIds(id) {
  return withExtTenantIdRequest({
    url: `/soyoungzg/api/sampleApplyRecord/delete?id=${id}`,
    method: 'get',
  });
}


/**
 *待支付的小样申请订单是否能支付
 *
 * @export
 * @param {*} id 小样申请记录id
 * @returns
 */

 export function sampleApplyRecordCheckPay(id) {
  return withExtTenantIdRequest({
    url: `/soyoungzg/api/sampleApplyRecord/checkPay?id=${id}`,
    method: 'get',
  });
}