<!-- 样品领用记录 -->
<template>
  <div class="app-container wrap">
    <div class="table-container">
      <div class="order-list-explain">
        样品领用记录规则：<br />
        1、样品可以使用的支付方式为：支付宝、微信；<br />
        2、样品到审核通过之后，其订单可以在【订单管理-订单查询】里面查看其订单发货状态；<br />
      </div>
      <form @submit.prevent="onSearch" class="filter-list">
        <div class="filter-item">
          <span class="label">样品状态:</span>
          <div class="content">
            <el-select clearable size="small" style="width: 100%" v-model="filter.status">
              <el-option
                :key="idx"
                :label="item.label"
                :loading="statusOptionsLoading"
                :value="item.value"
                v-for="(item, idx) in statusOptions"
              ></el-option>
            </el-select>
          </div>
        </div>
        <el-button native-type="submit" size="small" type="primary">查询</el-button>
        <el-button @click="onReset" size="small">重置</el-button>
      </form>
      <table border="0" cellpadding="0" cellspacing="0" class="refund-list">
        <thead class="table-head">
          <tr class="line">
            <th>
              <div class="cell">样品信息</div>
            </th>
            <th>
              <div class="cell">样品领用价</div>
            </th>
            <th>
              <div class="cell">领取数量</div>
            </th>
            <th>
              <div class="cell">收货人信息</div>
            </th>

            <th>
              <div class="cell">实收金额</div>
            </th>
            <th>
              <div class="cell">样品状态</div>
            </th>
            <th>
              <div class="cell">操作</div>
            </th>
          </tr>
        </thead>
        <tbody class="table-body" v-for="(item, index) in list" :key="index">
          <tr class="line tips-container">
            <td :colspan="ENV_CONFIG !== 'prod' ? '11' : '10'" class="tips-content">
              <div class="cell">
                <span>{{ item.createDate | parseDefaultTime() }}</span>
                <span>
                  <span v-if="item.applyNo">申请单号：{{ item.applyNo }}</span>
                  <el-tag
                    size="mini"
                    hit
                    class="el-tag-copy"
                    v-clipboard:copy="item.applyNo"
                    v-clipboard:error="onError"
                    v-clipboard:success="onCopy"
                    >复制</el-tag
                  >
                  <span v-if="item.type === 'GLOBAL'">
                    {{ filterCustoms(customsMap, item) }}
                  </span>
                  <span style="font-size: 14px;" v-if="item.contractInfo">
                    订单合同主体：{{ item.contractInfo.merchantTypeName }}-{{ item.contractInfo.contractInfoName }}
                  </span>
                </span>
              </div>
            </td>
          </tr>
          <tr class="line">
            <!-- 样品信息 -->
            <td class="goods-img goods-info">
              <div class="cell">
                <!-- <router-link
                  :to="
                    '/syoung/commodity/goods-detail/' + item.commodityId + '?sampleId=' + item.id
                  "
                  target="_blank"
                > -->
                <div class="goods-info--cell">
                  <oss-image :src="item.imgUrl" class="table-img" />
                  <div class="goods-info--cell--rightbox">
                    <div class="goods-info--cell--name">{{ item.commodityName }}</div>
                    <div class="subhead" v-if="item.commoditySpecValue">
                      规格:{{ item.commoditySpecValue }}
                    </div>
                  </div>
                </div>
                <!-- </router-link> -->
              </div>
            </td>
            <!-- 样品领用价 -->
            <td class="single">
              <div class="cell">
                <div class="t-box">¥{{ item.price }}</div>
              </div>
            </td>
            <!-- 领取数量 -->
            <td class="single border-right">
              <div class="cell">
                <div class="t-box">1</div>
              </div>
            </td>
            <!--  收货人信息 -->
            <td class="goods-info border-right">
              <div class="cell">
                <div class="goods-address">
                  {{ item.address.consignee }},{{ item.address.mobile }},{{
                    item.address.provinceName
                  }}{{ item.address.cityName }}{{ item.address.zoneName
                  }}{{ item.address.addressDetail }}
                </div>
              </div>
            </td>
            <!--  实收金额 -->
            <td class="border-right">
              <div class="cell">￥{{ item.price }}</div>
            </td>
            <!--  订单状态 -->
            <td class="border-right">
              <div class="cell">
                <div>
                  {{ item.statusName }}
                  <el-tooltip
                    class="item"
                    effect="dark"
                    :content="item.approveMsg"
                    placement="top-start"
                  >
                    <div slot="content">
                      驳回时间：{{ item.updateDate | parseTime('{y}-{m}-{d} {h}:{i}:{s}')
                      }}<br />驳回原因：{{ item.approveMsg }}
                    </div>
                    <i class="el-icon-question" v-if="item.status === 'REJECT'"></i>
                  </el-tooltip>
                </div>
                <!-- <div class="time-clock" v-if="item.status === 'WAIT_PAY'">
                  剩余:
                  <TimeClock
                    :date="item.expireTime"
                    :ref="'time_' + item.id"
                    :showMillisecond="false"
                  ></TimeClock>
                </div> -->
              </div>
            </td>
            <td class="border-right">
              <div class="cell">
                <el-button
                  @click="linkPay(item)"
                  size="mini"
                  :type="isPrimaryBtn(item, 'fk')"
                  class="order-list--btn"
                  v-if="item.status === 'WAIT_PAY'"
                >
                  立即支付
                </el-button>
                <el-button
                  @click="onDelete(item.id)"
                  size="mini"
                  class="order-list--btn"
                  v-if="item.status === 'CANCELED'"
                >
                  删除
                </el-button>
                <div v-if="item.status === 'PASS'">
                  <router-link
                    :to="'/syoung/order/inquiry/detail/' + item.id"
                    class="link linknone"
                    >查看订单详情</router-link
                  >
                </div>
              </div>
            </td>
          </tr>
          <div class="line-bottom-border"></div>
        </tbody>
      </table>
      <div class="empty" v-if="list.length === 0">暂无数据</div>
      <div class="pagination">
        <el-pagination
          :current-page="pageNo"
          :disabled="listLoading"
          :page-size="pageSize"
          :page-sizes="[10, 20, 30, 40]"
          :total="total"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
          background
          layout="total, sizes, prev, pager, next, jumper"
        ></el-pagination>
      </div>
    </div>
    <PayCode
      :postData="postData"
      @changePayType="changePayType"
      @sureRefresh="sureRefresh"
      ref="pay"
    ></PayCode>
  </div>
</template>
<script>
import listDictByType from '@/api/common/dict';
import {
  sampleApplyRecordMyList,
  handleDeleteIds,
  sampleApplyRecordCheckPay,
} from '@/api/sample-receipt-record';
import TimeClock from '@/components/TimeClock';
import CheckDeliveryDetail from '@/components/CheckDeliveryDetail';
import pick from 'lodash/pick';
import get from 'lodash/get';
//import pickBy from 'lodash/pickBy';
import cloneDeep from 'lodash/cloneDeep';
import MemberManagement from '@/components/views/checkout/MemberManagement'; // 报关
import PayCode from '@/components/Pay/PayCode';
import CancelOrder from '@/components/CancelOrder';
export default {
  components: {
    CheckDeliveryDetail,
    TimeClock,
    MemberManagement,
    PayCode,
    CancelOrder,
  },
  data() {
    const initFilter = {
      status: '', // 订单状态
    };
    return {
      postData: {}, // 扫码充值传参
      ENV_CONFIG: process.env.VUE_APP_ENV_CONFIG,
      initFilter,
      filter: cloneDeep(initFilter),
      statusOptions: [], // 订单状态列表
      list: [],
      statusOptionsLoading: true,
      listLoading: true,
      pageNo: 1,
      pageSize: 10,
      total: 0,
      customsMap: {},
      payType: this.getPayType(), // 支付方式，默认是微信支付,如果用户有切换支付方式则选择用户的支付方式
      rowData: {}, // 点击的的行数据
    };
  },
  computed: {
    // 过滤
    data() {
      // pickBy(this.filter, (val) => !!val);
      return this.filter.status;
    },
  },
  mounted() {
    this.fetchData();
    this.fetchStatus();
  },
  methods: {
    // 取消订单之后刷新数据
    cancelSuccess() {
      this.fetchData();
    },
    // 获取支付方式，默认是微信支付,如果用户有切换支付方式则选择用户的支付方式
    getPayType() {
      if (localStorage.getItem('otherPay')) {
        return localStorage.getItem('otherPay') === '微信' ? 'weChat' : 'alipay';
      }
      return 'weChat';
    },
    // 切换支付方式
    changePayType(value) {
      this.payType = value === '微信' ? 'weChat' : 'alipay';
      this.linkPay(this.rowData);
    },
    async linkPay(item) {
      this.rowData = item;
      // if (this.$refs['time_' + item.id][0].isStop) {
      //   this.$message.warning('已超出支付时间，请重新下单');
      //   return;
      // }

      try {
        const payAbleRes = await sampleApplyRecordCheckPay(item.id);
        if (!payAbleRes.data) {
          return this.$message.error(payAbleRes.msg);
        }
        if (!payAbleRes.success) {
          return this.$message.error(payAbleRes.msg);
        }

        if (!payAbleRes.data) {
          return this.$message.error('此订单无法支付');
        }
      } catch {
        return this.$message.error('支付错误');
      }
      this.postData = {
        bizType: 'SAMPLE',
        body: '水羊直供小样',
        id: item.id,
        payAmount: item.price,
        customerTel: item.address.mobile,
        customerIdNo: item.type === 'GLOBAL' ? item.orderGlobal.idcardNo : '',
        customerName: item.type === 'GLOBAL' ? item.orderGlobal.buyerName : '',
        goodsCount: 1,
        type: item.type,
        ownCompanyId: item.ownCompanyId || '',
        brandType: item.brandType || '',
      };
      sessionStorage.setItem('payData', JSON.stringify(this.postData));
      window.open(`/syoung/pay/list?type=${item.type}&goodsType=sample`);
    },
    filterCustoms(customsMap, item) {
      return get(customsMap, get(item, 'orderGlobal.customs'), '');
    },
    // 复制申请单号
    onCopy(e) {
      this.$message.success('申请单号' + e.text + '复制成功');
    },
    onError(e, text = '申请单号') {
      // console.log(e);
      this.$alert(text + '复制失败', '请手动复制', {
        confirmButtonText: '确定',
        callback: action => {
          this.$message({
            type: 'info',
            message: `action: ${text}`,
          });
        },
      });
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.pageNo = 1;
      this.fetchData();
    },
    handleCurrentChange(val) {
      this.pageNo = val;
      this.fetchData();
    },
    sureRefresh() {
      this.fetchData();
    },
    fetchData() {
      this.listLoading = true;
      const listQuery = pick(this, ['pageNo', 'pageSize', 'data']);
      sampleApplyRecordMyList(listQuery)
        .then(response => {
          this.list = response.data.list || [];
          this.total = response.data.total;
        })
        .finally(() => {
          this.listLoading = false;
        });
    },
    onDelete(id) {
      this.$confirm('是否删除该记录?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          handleDeleteIds(id).then(res => {
            this.fetchData();
            this.$message({
              type: 'success',
              message: res.msg,
            });
          });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消',
          });
        });
    },
    fetchStatus() {
      // 订单状态列表
      this.statusOptionsLoading = true;
      listDictByType('sample_apply_record_status')
        .then(rs => {
          const res = rs.data.map(item => ({
            value: item.value,
            label: item.label,
          }));
          this.statusOptions = res;
        })
        .finally(() => {
          this.statusOptionsLoading = false;
        });
    },
    onSearch() {
      this.pageNo = 1;
      this.pageSize = 10;
      this.fetchData();
    },
    onReset() {
      this.pageNo = 1;
      this.pageSize = 10;
      this.filter = cloneDeep(this.initFilter);
      this.$nextTick(() => {
        this.fetchData();
      });
    },
    isPrimaryBtn(item, type) {
      if (
        item.status === 'DELIVERED' ||
        item.status === 'RECEIVED' ||
        item.status === 'FINISHED' ||
        item.status === 'REFUND'
      ) {
        return type === 'wl' ? 'primary' : '';
      }
      if (item.status === 'WAIT_PAY') {
        return type === 'fk' ? 'primary' : '';
      }
      if (
        item.status !== 'WAIT_PAY' &&
        item.orderSource !== 'SAMPLE' &&
        item.orderSource !== 'SPECIAL_BUY'
      ) {
        return type === 'gwc' ? 'primary' : '';
      }
    },
  },
};
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
@import 'src/styles/goods-table.scss';
.refund-list .table-body .line td.single .t-box {
  height: auto;
}
.order-list-explain {
  color: #e98e5e;
  background: #fffaf1;
  padding: 10px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 20px;
}
.flag {
  width: 20px;
  cursor: pointer;
}
.dialog-goods-img {
  display: block;
  width: 60px;
  height: 60px;
}
.dialog-section {
  background-color: #fff;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
  margin-top: 20px;
  .title {
    font-weight: 500;
    border-bottom: 1px solid #e8e8e8;
    padding: 18px 30px;
    font-size: 16px;
    user-select: none;
  }
  .text-container {
    display: flex;
    .text {
      display: flex;
      width: 50%;
      box-sizing: border-box;
      margin-bottom: 16px;
      min-width: 334px;
      text-align: right;
      .label {
        padding-right: 16px;
        white-space: nowrap;
        display: inline-block;
        line-height: 32px;
      }
      .content {
        flex: 1 1;
      }
    }
  }
  .text {
    padding: 18px 30px;
    font-size: 14px;
    &.second {
      padding-top: 0;
    }
    &.p0 {
      padding-bottom: 0;
    }
  }
}
.dialog-footer {
  padding: 10px 20px;
}
.filter-list {
  align-items: center;
  .filter-item {
    margin-bottom: 0;
    .label {
      width: 64px;
    }
  }
}
.failure {
  color: red;
}

.goods-name {
  .name-r {
    padding-left: 54px;
  }
}
.date-picker {
  width: 260px;
}
.filter-item-btn {
  justify-content: space-between;
}

.btns-open {
  width: 66.6% !important;
  padding-right: 0 !important;
}

.linknone:hover {
  text-decoration: underline;
}
.order-list--btn {
  &.el-button--primary:hover,
  &.el-button--primary:focus {
    background: #df3a59;
    border-color: #df3a59;
    color: #ffffff;
  }
  &.el-button--primary {
    color: #ffffff;
    background-color: #ab0033;
    border-color: #ab0033;
  }
  border: 1px solid #ab0033;
  color: #ab0033;
}
.goods-address {
  text-align: left;
  padding: 0 5px;
}
</style>
